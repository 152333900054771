.bottomnavbar .nav-item {
  justify-content: center;
  display: flex;
  text-align: center;
  width: 20%;
  position: relative;
}

.w-20 {
  width: 25%!important;
}

.bottomnavbar svg {
  font-size: 18pt;
  margin: 0px auto 2px auto;
}

.bottomnavbar p {
  font-size: 12px;
  font-weight: bold;
}

.bottomnavbar .nav-link {
  height: 49pt;
  display: grid;
  align-items: center;
  justify-content: center;
}

.bottomnavbar .nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #666666;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.bottomnavbar .navbar-nav .nav-link.active, .bottomnavbar .navbar-nav .show > .nav-link {
  color:#AD0000 ;
}

