@media (min-width: 768px) {
  .searchbarrow .col:first-child {
    padding-right: .5rem;
  }
}

.searchbarrow .col {
  padding: 0;
}

@media (min-width: 768px) {
  .searchbarrow .col:last-child {
    padding-left: .5rem;
  }
}

.filterdiv {
  background-color: #111111;
  padding: 10px 1rem 1rem 1rem;
  border-radius: 1.5rem;
  margin-top: .5rem;
}

