







.searchbox:focus {
  border: 1px solid var(--turuncu);
  box-shadow: none;
}

.profiledropdown::after {
  display: none;
}

.profiledropdown {
  display: flex;
  font-size: 1.5rem;
  color: #b1b1b1;
  box-shadow: none !important;
}

.anasearchicon {
  display: grid;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
}

.anasearchicon svg {
  color: var(--turuncu);
  font-size: 1.9rem;
}

.accordion-button .kapatma {
  display: none;
}

@media (max-width: 768px) {
  .mobildegizle {
    display: none !important;
  }
}

.searchaccordion-header {
  margin-right: 1rem;
}

.navbar-brand {
  justify-content: center;
  align-items: center;
  display: flex;
}

.navbarprofilemenu {
  right: -1rem;
  left: auto !important;
  border-radius: 1rem;
  border-color: #e4e4e4;
  margin-top: 0.5rem !important;
}

.navbarprofilemenu::before {
  content: "";
  /*width: 0;*/
  /*height: 00;*/
  position: absolute;
  top: 0;
  border: 0.5rem solid transparent;
  border-top: 0;
  border-bottom: 0.75rem solid #ffffff;
  transform: translateY(-100%);
  right: 1.25rem;
}

.profiledropdown:hover {
  color: #ff0000;
}

body {
  font-family: "Gilroy-Light ☞";
}

.navbarprofilemenu {
  border: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.navbarprofilemenu svg,
.navbarprofilemenu i {
  margin-right: 1rem;
}

.navbarprofilemenu a {
  font-weight: 300;
}

.navbarprofilemenu .dropdown-item {
  color: #414141;
  padding-top: 8px;
  padding-bottom: 8px;
}

.user-active {
  background-color: #747474;
}

.bottomnavbar {
  height: 67.3px;
}

.rentorbuyslider input {
  visibility: hidden;
  /*margin-left: 0!important;*/
  width: 0;
  height: 0;
}

.rentorbuyslider2 input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.rentorbuyslider .form-check {
  padding: 0;
  display: inline-block;
  margin-bottom: -1px;
}

.rentorbuyslider2 .form-check {
  padding: 0;
  display: inline-block;
  margin-bottom: 1px;
}

.rentorbuyslider input:checked + label {
  background-color: rgb(17, 17, 17);
  color: #fff !important;
  font-family: "Gilroy-Bold ☞";
  border-style: solid;
  font-size: 1rem;
  border-color: rgb(17, 17, 17);
  border-width: 1px 1px 0px 1px;
}

.rentorbuyslider2 input:checked + label {
  background-color: rgb(17, 17, 17);
  color: #000000 !important;
  font-family: "Gilroy-Bold ☞";
  border-style: solid;
  font-size: 1rem;
  border-color: rgb(17, 17, 17);
  border-width: 1px 1px 0px 1px;
}

.rentorbuyslider .form-check label {
  cursor: pointer;
  padding: 1rem 1.5rem;
  border-width: 1px;
  border-style: solid;
  font-size: 1rem;
  border-color: rgb(17, 17, 17);
  line-height: 24px;
}

.filterinnerdiv .searchbarrow:first-child {
  margin-bottom: 0.5rem;
}

.myinputdiv label {
  color: #fff;
  margin-left: 0.5rem;
  margin-bottom: 0.25rem;
  font-family: "Gilroy-Bold ☞";
}

.filterinnerdiv .searchbarrow {
  margin: 0;
}

.inputwithicondiv {
  position: relative;
}

.inputwithicondiv svg,
.inputwithicondiv i {
  position: absolute;
  bottom: 50%;
  left: 0.5rem;
  transform: translateY(50%);
  font-size: 1.5rem;
  color: #111111;
}

.inputwithicondiv .myinput {
  padding-left: 2.5rem !important;
}

.searchbarrow .myinput {
  padding-left: 0.5rem;
}

.searchbarrow .row {
  margin: 0;
}

.myinput.myinputdropdown {
  width: 100%;
}

.myinputdropdownmother .dropdown-menu {
  border: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 1rem;
}

.myinputdropdownmother .dropdown-menu .form-control {
  border-radius: 0;
  background-color: #ffffff;
  border: 2px solid #f2f4f8;
  height: 3rem;
  text-align: center;
}

.myinput.myinputdropdown {
  padding-left: 0.5rem;
  background-color: #ffffff;
  color: #111;
  text-align: left;
}

.searchbarrow .myinput {
  background-color: #fff;
}

.myinputdropdownmother .dropdown-menu .btn {
  border-radius: 0.5rem;
}

.myinputdiv .dropdown-menu label,
.myinputdiv2 .dropdown-menu label {
  color: #111;
  margin-left: 0;
  font-family: "Gilroy-Light ☞";
  width: 100%;
}

.radiolist .form-check {
  margin-bottom: 0 !important;
}

.radiolist .form-check:last-child .form-check-label {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  main {
    padding-bottom: 3rem;
    padding-top: 0rem;
  }
}

@media (max-width: 768px) {
  main {
    padding-bottom: 0px !important;
    padding-top: 1rem;
    min-height: 100vh;
  }
}

@media (max-width: 768px) {
  .filterinnerdiv .searchbarrow .col:first-child {
    margin-bottom: 1rem;
  }
}

.yataydahep {
  display: flex;
  gap: 0.5rem;
}

.yataydahep .myinputdropdownmother,
.yataydahep .dropmothermother {
  width: 100%;
}

@media (max-width: 768px) {
  .yataydahep .dropmothermother .btn::after {
    right: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 768px) {
  .searchbarrow .myinput.myinputdropdown {
    text-align: left;
  }
}

.cardbody {
  height:15rem;
  border: solid 1px #ddd6;
  padding: 1rem;
  border-radius: 0 0 1.5rem 1.5rem;
}

.cardfullbody {
  padding: 1rem;
  background: #fff9;
  border: solid 1px #ddd6;
  border-radius: 1.5rem;
}

.itemprice {
  font-family: "Gilroy-Bold ☞";
}

.itemprice .aedyear {
  font-size: 0.9rem;
}

.itemlink {
  text-decoration: none;
  color: #111111;
  position: relative;
}

.itemlink:hover {
  color: #282828;
}

.itemmotherdiv:hover .carousel-control-prev,
.itemmotherdiv:hover .carousel-control-next,
.itemmotherdiv:hover .carousel-indicators {
  opacity: 1;
}

.carousel-control-prev,
.carousel-control-next,
.carousel-indicators,
.carousel-control-prev:focus,
.carousel-control-next:focus {
  opacity: 0;
}

.itemmotherdiv .carousel-item img {
  aspect-ratio: 325/250;
  object-fit: cover;
}

.br-10 > img {
  border-radius: 25px !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: rgba(255, 255, 255, 0.9);
  background-image: none;
  display: grid;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 1.2rem;
  border-radius: 50%;
}

.rentbuyform {
  padding-left: 12px;
  padding-right: 12px;
}

.carousel-inner {
  border-radius: 1.5rem 1.5rem 0 0;
  overflow-x: hidden;
}

.itemmotherdiv {
  border-radius: 1.5rem;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.05);
}

.cardbody .locationtext {
  font-size: 0.9rem;
  display: flex;
  /*cursor: pointer;*/
  color: #111111;
}

.cardbody .cardfootertext {
  font-size: 0.9rem;
  display: flex;
  /*cursor: pointer;*/
  color: #777777;
  margin-bottom: 0;
}

.cardbody .locationtext i {
  font-size: 1rem;
}

.cardfeatures {
  display: flex;
  font-family: "Gilroy-Bold ☞";
  gap: 12px;
  margin-bottom: 1rem;
}

.cardfeatures .cardfeature {
  display: flex;
  align-items: center;
  gap: 6px;
}

.cardfeatures .cardfeature i,
.cardfeatures .cardfeature svg {
  margin-bottom: 3px;
}

.cardfeatures .cardfeature .cardsqft {
  font-size: 0.8rem;
}

.itemlink .badge.type {
  position: absolute;
  z-index: 2;
  top: 0.5rem;
  left: 0.5rem;
  background-color: #000;
  font-family: "Gilroy-Bold ☞";
  line-height: 0.9rem;
}

.cardcarousel-motherdiv {
  position: relative;

  
}

.cardcarousel-motherdiv .typebadge {
  content: "Apartment";
  position: absolute;
  z-index: 3;
  top: 1rem;
  left: 1rem;
  color: #fff;
  background-color: #000;
  font-size: 0.9rem;
  padding: 1px 7px;
  border-radius: 0.5rem;
  font-family: "Gilroy-Light ☞";
  text-decoration: none;
}

.itemmotherdiv .cardbody .cardagencysection {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}

.cardagencyimage,
.cardpersonimage {
  object-fit: cover;
  background-size: cover;
  border-radius: 50%;
}

.ayrac {
  border-left: 0.5px solid #acacac;
}

.agencyandpersonavatarwrapper {
  position: relative;
}

.cardpersonimage {
  width: 2.6rem;
  height: 2.6rem;
}

.cardagencyimage {
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  right: -8px;
  bottom: -8px;
  border: 1px solid #f8f8fb;
}

.cardcontactbutton {
  background-color: #000;
  color: #fff;
  border: none;
  box-shadow: none;
  display: grid;
  align-items: center;
  border-radius: 0.75rem;
  padding: 0.6rem 0.9rem;
  font-size: 1.2rem;
}

.cardcontactdiv {
  gap: 0.25rem;
  display: flex;
  align-items: center;
}

.cardagencyimagesec {
  display: flex;
  gap: 0.5rem;
}

.cardcontactbutton:hover {
  color: #ebeff6;
}

.heartbutton-card {
  position: absolute;
  z-index: 3;
  right: 1rem;
  top: 1rem;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.5);
  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: 1px;
  font-size: 1.5rem;
  cursor: pointer;
  transition-duration: 0.1s;
}

.heartbutton-card:hover,
.heartbutton-card:active {
  -webkit-text-fill-color: rgba(255, 0, 0, 0.49);
}

.activeHeart {
  -webkit-text-fill-color: rgba(255, 0, 0, 0.49) !important;
}

.heartbutton-card:hover {
  transform: scale(1.1);
}

footer {
  background-color: #111;
}

footer a {
  text-decoration: none;
  font-family: "Gilroy-Light ☞";
}

footer h3 {
  font-family: "Gilroy-Bold ☞";
}

.breadcrumbdiv {
  padding-left: 2rem;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.breadcrumbdiv a {
  text-decoration: underline;
  color: #747474;
}

.breadcrumb .breadcrumb-item:last-child a {
  font-family: "Gilroy-Bold ☞";
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.page-item [aria-label="Next"],
.page-item [aria-label="Previous"] {
  border-style: solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  border-color: #bcbcbc;
  border-width: 0.5px;
  justify-content: center;
  aspect-ratio: 1;
}

.paginationnav {
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
}

.page-item [aria-label="Next"]:hover,
.page-item [aria-label="Previous"]:hover {
  background-color: #ebebeb;
}

.socialiconsfooter li a {
  color: #f2f4f8;
  text-decoration: none;
  font-size: 1.3rem;
}

.ps2rem {
  padding-left: 2rem;
}

.searcsum {
  font-family: "Gilroy-Bold ☞";
  margin-bottom: 0;
}

.px-2rem {
  padding-left: 2rem;
  padding-right: 2rem;
}

.myoutlineselect {
  background-color: #ffffff00;
  border-radius: 0.75rem;
}

.searchbarrow .myinput.myinputdropdown::after {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.myinputdiv .dropdown-menu .radiolist label,
.myinputdiv2 .dropdown-menu .radiolist label {
  display: flex;
  justify-content: space-evenly;
}

.myinputdiv2 .dropdown-menu .radiolist.monolabel label {
  display: flex;
  justify-content: flex-start;
}

.myinputdiv .dropdown-menu .radiolist.monolabel label {
  justify-content: flex-start;
}

.searchbarrow .myinput.myinputdropdown.withoutdownicon {
  text-align: left;
}

.myinputdiv .dropdown-menu label.mainlabel {
  font-family: "Gilroy-Bold ☞";
}

.sonucdiv {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .sonucdiv .searcsum {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0.5rem;
  }
}

.shortby {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sonucdiv .shortby {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 100%;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .sonucdiv .shortby {
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 100%;
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .sonucdiv {
    padding-left: 1rem;
  }
}

@media (max-width: 768px) {
  footer {
    padding-bottom: 0px;
  }
}

.categoriesnavitem {
  transform: translateY(-30%);
  background-color: #ffffff;
  border-radius: 50%;
  display: grid !important;
  align-items: center !important;
  background-color: #111111;
  aspect-ratio: 1;
  border-style: solid;
  border-width: 1px;
  border-color: #dee2e6;
  z-index: 1;
  width: 49pt;
}

.bottomnavbar .categoriesnavitem svg {
  margin-bottom: 0;
  color: #fff;
}

.bottomnavbar .nav-item.ortanavitem .nav-link.active::before {
  position: absolute;
  content: "";
  display: block;
  background-color: #ff0000;
  width: 0.5rem;
  height: 0.5rem;
  bottom: -0.75rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}

.categoriesmobilemenu {
  width: 2rem;
  height: 2rem;
  position: fixed;
  left: 50%;
  bottom: 0%;
  transform: translateX(-50%);
  z-index: 9;
  background-color: #f2f4f8;
  border-radius: 50%;
  transition-duration: 0.5s;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.5);
  display: grid;
  align-items: center;
  justify-content: center;
  font-family: "Gilroy-Light ☞";
  padding: 1rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.mymenubg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 50%;
  bottom: 0%;
  transform: translateX(-50%);
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.14);
  transition-duration: 0.5s;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(3px);
  display: none;
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
}

.categoriesmobilemenu ul li:not(:last-child) {
  margin-bottom: 1rem;
}

.categoriesmobilemenu ul li a {
  text-decoration: none;
  color: #111;
}

.categoriesmobilemenu ul li {
  text-align: center;
}

#mymenulist.hide {
  opacity: 0;
}

#mymenulist.show {
  opacity: 1;
}

#mymenulist {
  transition-duration: 0.2s;
}

.gilroybold {
  font-family: "Gilroy-Bold ☞" !important;
}

strong {
  font-family: "Gilroy-Bold ☞" !important;
}

@media (min-width: 992px) {
  .categoriesmobilemenu {
    display: none;
  }
}

.blogcardcover {
  aspect-ratio: 325/250;
  object-fit: cover;
  width: 100%;
  border-radius: 1.5rem 1.5rem 0 0;
}

.ofthedaycard {
  background-color: #ebeff6;
  border-style: solid;
  border-color: #fff;
  border-width: 4px;
  border-radius: 1.5rem;
  background-image: url("../../assets/img/ofthedayimage.wepb");
  background-size: cover;
  display: grid;
  align-items: end;
}

.blogheader {
  margin-bottom: 1rem;
}

.ofthedaylink {
  text-decoration: none;
  color: #111111;
}

.ofthedayheading {
  font-family: "Gilroy-Bold ☞";
}

.ofthedaylink:hover {
  color: #282828;
}

.ofthedaytext {
  margin-bottom: 0;
  color: #747474;
}

.bigblogcardbody {
  padding: 1rem;
  display: grid;
  align-content: space-around;
  background-color: #fff;
  border-radius: 1.5rem;
  border-style: solid;
  border-color: #fff;
  border-width: 0px;
  height: 100%;
}

.bigblogcardbody p.small {
  color: #969696;
  margin-bottom: 0;
}

.bigblogcardrow {
  background-color: #fff;
  border-radius: 1.5rem;
  height: 100%;
}

.bigblogcardimage {
  background-size: cover;
  background-position: center;
  border-radius: 1.5rem;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-width: 6px;
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .bigblogcardimage {
    aspect-ratio: 1;
  }
}

@media (max-width: 992px) {
  .ofthedaycard {
    aspect-ratio: 1;
  }
}

@media (min-width: 992px) {
  .ofthedaycard {
    height: 100%;
  }
}

@media (min-width: 1400px) {
  .ofthedaycard {
    height: auto;
    aspect-ratio: 1;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">");
}

.bu-class-silinecek {
  height: 70vh;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  background-image: url("https://lh3.googleusercontent.com/1g_8dMsxYfoZ_VS338syoSlyVpl5yD9Ho5eV7clDTqPGwaKElIciZTvzvxZeaM_-dE3vYWh6oZ4FyuUg3bRo21f6Ue56_ADwSBmh6kasSKS3EA5gQE=w2400-rj");
  background-size: cover;
  background-position: center;
  color: #fff;
  position: relative;
  text-align: center;
  align-content: center;
}

.blogimage {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  background-size: cover;
  background-position: center;
  color: #fff;
  position: relative;
  text-align: center;
  align-content: center;
}

.bu-class-silinecek::after {
  content: "Slider will come here please delete the class";
  font-size: 2rem;
  text-align: center;
}

.informationcard {
  padding: 1.5rem 1.5rem 1rem 1.5rem;
}

.vertical-line {
  border-left: 1px solid #111;
}

.mybox {
  border: solid 1px #ddd6;
  color: #111111;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.05);
  border-radius: 1.5rem;
}

.informationcard .locationtext span {
  text-decoration: underline;
}

.informationcard .cardpersonimage {
  width: 3rem;
  height: 3rem;
}

.info-contact-btn {
  display: block;
  text-align: center;
  border-radius: 0.75rem;
}

.informationcard-contactbuttons .btn:not(:last-child) {
  margin-bottom: 0.5rem;
}

.info-contact-btn svg {
  font-size: 1.2rem;
}

.info-contact-btn {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.quickview p {
  margin-bottom: 0;
  font-family: "Gilroy-Light ☞";
}

.quickview .col {
  text-align: center;
  justify-content: center;
  display: flex;
}

.anotherheartbutton {
  -webkit-text-fill-color: #ffffff00;
  -webkit-text-stroke-color: #747474;
  -webkit-text-stroke-width: 1.5px;
  /*font-size: 1.1rem;*/
  cursor: pointer;
  transition-duration: 0.1s;
}

.lightlink:hover {
  color: #111;
}

.lightlink {
  color: #747474;
  text-decoration: none;
}

a:hover .anotherheartbutton {
  -webkit-text-fill-color: #ff0000;
  -webkit-text-stroke-color: #ff0000;
}

a.active .anotherheartbutton {
  -webkit-text-fill-color: #ff0000;
  -webkit-text-stroke-color: #ff0000;
}

@media (max-width: 768px) {
  .breadcrumb {
    font-size: 0.75rem !important;
    margin-bottom: 2rem;
  }
}

@media (min-width: 768px) {
  .breadcrumbdiv.withbacklink {
    display: flex;
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .breadcrumbdiv.withbacklink .breadcrumb {
    margin-top: 0.5rem;
  }
}

.breadcrumbdiv a.backtohomelink {
  color: #111;
  display: flex;
  gap: 0.75rem;
  text-decoration: none;
}

.breadcrumbdiv a.backtohomelink span:last-child {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0.4rem;
  }
}

@media (max-width: 768px) {
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.4rem;
  }
}

.floatsearchbutton {
  position: fixed;
  right: 2rem;
  color: #fff;
  background-color: #111;
  text-decoration: none;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  outline: 3px solid rgba(255, 255, 255, 0.5);
  z-index: 3;
  bottom: -6rem;
  border: 0;
  transition-duration: 0.4s;
  cursor: pointer;
}

.search-engine-modal {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-out;
}

.search-modal-close {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-out;
}

.search-modal-content {
  border-radius: 1.5rem;
  background-color: #111;
  color: #f2f4f8;
  margin: auto;
}

@media (max-width: 992px) {
  .floatsearchbutton.showit {
    bottom: 5rem;
    border-radius: 200px;
    width: 3rem;
    height: 3rem;
  }
  .search-modal-content {
    margin-top: 1rem;
  }
}

@media (min-width: 992px) {
  .floatsearchbutton.showit {
    bottom: 1.5rem;
  }

  .search-modal-content {
    margin-top: 1.5rem;
    max-width: 900px;
  }
}

@media (min-width: 992px) {
  .floatsearchbutton {
    left: 50%;
    transform: translateX(-50%);
    width: 9rem;
    display: flex;
    gap: 1rem;
    height: 3rem;
    border-radius: 2rem;
  }
}

@media (max-width: 992px) {
  .floatsearchbutton {
    right: 2rem;
  }
}

.floatsearchbutton:hover {
  color: #fff;
}

@media (min-width: 992px) {
  .floatsearchbutton:hover {
    width: 10rem;
    height: 3.2rem;
  }
}

.searchmodal .modal-content {
  border-radius: 1.5rem;
  background-color: #111;
  color: #f2f4f8;
  border-width: 0;
}

.searchmodal .btn-close {
  background-color: #fff;
  border-radius: 50%;
  margin-right: 0;
  box-sizing: content-box;
  padding: 8px;
  color: #fff;
}

.searchmodal .modal-header {
  border: none;
  justify-content: space-between;
}

button.temizle {
  background: none;
  border: none;
}

.showmore {
  border-radius: 0.75rem;
  margin-top: 0.5rem;
}

.showmore .showmoretext {
  display: inline;
}

.showmore:not(.collapsed) .showmoretext {
  display: none;
}

.showmore.collapsed .hidetext {
  display: none;
}

.showmore .hidetext {
  display: inline;
}

.showmore:not(.collapsed) .accordionarrow {
  transform: rotate(-90deg);
}

.showmore .accordionarrow {
  transition-duration: 0.5s;
}

.detailstable tr td:first-child,
.detailstable tr td:nth-child(3),
.detailstable tr td:nth-child(5) {
  font-family: "Gilroy-Bold ☞";
}

.stickcard {
  position: sticky;
  top: 2rem;
}

.amenitiestable td {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.detailstable td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.detailstable i {
  font-size: 1.5rem;
}

.amenitiestable i {
  margin-right: 1rem;
  font-size: 1.5rem;
}

.amenitiestable span {
  font-size: 1.1rem;
  display: inline-block;
}

.detailstable table,
.amenitiestable table {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .detailstable tr td:last-child {
    text-align: right;
  }
}

@media (max-width: 768px) {
  .quickview p {
    font-size: 1rem !important;
  }
}

.amenitiestable .col {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.mybox .accordion p {
  line-height: 30px;
}

.mapgoeshere {
  border-radius: 1.5rem;
}

.radiosbolum {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.form-check-input {
  border-color: #111111;
}

.radiosbolum .form-check-input {
  background-color: #f2f4f8;
}

.mybtn {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 0.75rem;
  padding-left: 0.5rem;
}

.form-check-input {
  background-color: rgba(242, 244, 248, 0);
}

.myinput.myinputdropdown::after {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}

.profilepicturediv {
  display: flex;
  gap: 0.5rem;
  background-color: #fff;
  border-radius: 1.5rem;
  padding: 0.5rem;
}

.profilepicturewillcomehere {
  aspect-ratio: 1;
  border-radius: 1rem;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  background-color: #ebeff6;
  font-family: "Gilroy-Bold ☞";
}

.profilepicturewillcomehere.empty::after {
  content: "No image uploaded";
  z-index: 0;
  padding: 0.5rem;
}

.addprofileimagediv {
  width: 100%;
  display: grid;
  align-content: space-around;
  gap: 0.5rem;
}

.addprofileimagediv .btn.mybtn {
  height: 3.5rem;
}

@media (max-width: 768px) {
  .profilepicturewillcomehere {
    width: 8rem;
    height: 8rem;
    aspect-ratio: 1;
  }
}

@media (min-width: 768px) {
  .profilepicturewillcomehere {
    width: 9rem;
    height: 9rem;
  }
}

@media (max-width: 768px) {
  .propertypreferencesform {
    width: 100%;
  }
}

/* .profilinoldugudiv {
  display: none;
}

body.logged .profilinoldugudiv {
  display: flex;
} */

body.logged .logregmotherdiv {
  display: none;
}

.logregmotherdiv {
  display: flex;
  gap: 0.5rem;
}

.logregmotherdiv .mybtn {
  font-family: "Gilroy-Bold ☞";
  width: 5rem;
}

.searchmodal .filterdiv {
  background: none;
}

.searchmodal .myinput {
  background-color: #fff;
}

.logregmodal .modal-content {
  background-color: #f2f4f8;
}

.logregmodal label,
.logregmodal .form-text {
  padding-left: 0.5rem;
  display: block;
}

.logregmodal .form-check-input {
  background-color: #ffffff00;
}

.orspan {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f2f4f8;
  padding: 0 2rem;
}

.loginsocialicon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.mybtn:hover .loginsocialicon {
  color: #fff;
}

.myinput.myoutlinedinput {
  background: none;
  border-style: solid;
  border-width: 1px;
  border-color: #111;
  color: #111;
}

.propertypreferencesform .form-label,
.careerform .form-label {
  padding-left: 0.5rem;
}

textarea.myinput {
  height: auto;
}

.creerdescription {
  line-height: 28px;
}

.careersticky {
  position: sticky;
  top: 2rem;
}

.terms ol li ol li {
  list-style: upper-alpha;
}

.terms ol li ol li ol li {
  list-style: lower-alpha;
}

.rentorbuymodalslider {
  background-color: #f2f4f8;
  padding: 0.25rem;
  border-radius: 1rem;
}

.rentorbuyslider2 {
  padding: 0.25rem 0.25rem;
  border-radius: 1rem;
  display: inline-flex;
  background-color: #111;
}

.searchmodal .rentorbuyform .form-check:last-child label {
  color: #fff;
  border: none;
}

.searchmodal .rentorbuyform .form-check:first-child label {
  color: #fff;
  border: none;
}

.rentorbuyform2 .form-check label {
  color: #ffffff;
  border: none;
}

.searchmodal .rentorbuyform .form-check label {
  padding: 0.75rem 1.5rem;
  border-radius: 0.75rem 0.75rem 0.75rem 0.75rem !important;
}

.rentorbuyform2 .form-check label {
  padding: 0.75rem 1.5rem;
  border-radius: 0.75rem 0.75rem 0.75rem 0.75rem !important;
  cursor: pointer;
}

.searchmodal .rentorbuyslider input:checked + label {
  background-color: #fff;
}

.rentorbuyslider2 input:checked + label {
  background-color: #ffffff;
}

.closecol {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

@media (max-width: 992px) {
  .myslidercol .rentorbuyslider .rentorbuyform {
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .myslidercol .rentorbuyslider .rentorbuyform {
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .titlecol {
    margin-bottom: 1rem;
    justify-content: flex-end;
  }
}

.titlecol {
  padding: 0;
  align-items: center;
  display: flex;
}

.myparallax img {
  border-radius: 1.5rem;
}

.features .card {
  border: none;
  border-radius: 1.5rem;
}

@media (min-width: 768px) {
  .tepesection {
    padding: 2.5rem;
  }
}

@media (max-width: 768px) {
  .tepesection {
    padding: 2rem 0.5rem 0.5rem;
  }
}

.featuresrow .col .bs-icon-md.bs-icon-rounded.bs-icon svg {
  font-size: 5rem;
}

.featuresrow .col .bs-icon-md.bs-icon-rounded.bs-icon {
  margin-bottom: 1.5rem !important;
}

.featuresicon {
  font-size: 5rem;
  margin-bottom: 1rem;
}

.filterlistbuttons .btn {
  width: 100%;
}

.rangefilterbtn {
  width: 100%;
  height: 3rem;
  border: none;
  border-radius: 0;
  color: #000;
  background-color: #ffffff;
  border-style: solid;
  border-color: #f2f4f8;
  border-left-width: 2px;
  border-right-width: 2px;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rangeleftrighttdiv .rangefilterbtn:first-child {
  border-top-width: 2px;
}

.rangeleftrighttdiv .rangefilterbtn:last-child {
  border-bottom-width: 2px;
}

.rangeleftrighttdiv {
  width: 100%;
}

.rangeleftrighttdiv.makeitscrollable {
  overflow-y: scroll;
  max-height: 12rem;
}

.rangefilterbtn:hover {
  background-color: #f2f4f8;
}

.myoulinedropdown {
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
}

.radiolistdiv {
  padding: 0.75rem;
}

.radiolistdiv .form-check:not(:last-child) {
  margin-bottom: 0.5rem;
}

.mail-modal .modal-title {
  font-family: "Gilroy-Light ☞";
}

.mail-modal .modal-title .motaltitle-name {
  font-family: "Gilroy-Bold ☞";
}

.emailmodal-profileimage {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #ebeff6;
  background-size: cover;
  background-position: center;
}

.emailmodal-productimage {
  width: 4rem !important;
  height: 4rem;
  border-radius: 1rem;
  background-color: #ebeff6;
  background-size: cover;
  background-position: center;
}

.emailmodal-flexdiv {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
}

.mailmodalprofilename {
  font-family: "Gilroy-Bold ☞";
  font-size: 1.1rem;
}

.emailmodalimagesaver {
  min-width: 4rem;
}

.mytabs .nav-tabs .nav-link {
  color: #111;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #cccccc;
}

.mytabs .nav-tabs .nav-link.active {
  font-family: "Gilroy-Bold ☞";
}

.mytabs .nav-tabs {
  margin-bottom: 0.5rem;
  border: none;
}

.lookingforheading {
  margin-bottom: 0;
}

@media (min-width: 991px) {
  .lookingfordiv {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .lookingfordiv {
    justify-content: flex-start;
    text-align: start;
    align-items: flex-start;
  }
}

@media (max-width: 768px) {
  .mail-modal .radiosbolum {
    display: grid !important;
  }
}

.floatmenu {
  position: fixed;
  background: linear-gradient(127deg, #f2f4f8, #ffffff 100%);
  top: 50%;
  display: inline-block;
  left: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.05);
  transform: translateY(-50%);
}

.floatmenu a {
  color: var(--bs-gray-700);
  text-decoration: none;
  font-size: 1.2rem;
  white-space: nowrap;
  border-radius: 1rem;
  transition-duration: 0.2s;
  width: 20px;
}

.floatmenu li span {
  opacity: 0;
  padding: 0.75rem 1rem;
  background-color: #111;
  color: #fff;
  transition-duration: 0.3s;
  margin-left: -9rem;
  z-index: -9;
  border-radius: 2rem;
  font-size: 1rem;
  font-family: "Gilroy-Bold ☞";
  margin-bottom: 0;
}

.floatmenu li:hover svg {
  scale: 1.2;
}

.floatmenu li:hover span {
  opacity: 1;
  margin-left: 1.9rem;
}

.floatmenu li:hover:not(:first-child) {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.floatmenu li:hover:first-child {
  padding-top: 1rem;
  padding-bottom: 1.2rem;
}

.floatmenu li:hover:last-child {
  padding-top: 1.2rem;
  padding-bottom: 1rem;
}

.floatmenu li {
  transition-duration: 0.2s;
  padding: 0.5rem 1rem;
}

.floatmenu a svg {
  transition-duration: 0.2s;
  z-index: 9;
}

.floatmenu li:first-child {
  padding-top: 1rem;
}

.floatmenu li:last-child {
  padding-bottom: 1rem;
}

@media (min-width: 991px) {
  .lookingfordiv {
    align-items: center;
  }
}

@media (min-width: 768px) {
  .text-end-md {
    text-align: right;
  }
}

@media (max-width: 768px) {
  .insidediv {
    width: calc(85% - 2rem);
    margin: auto;
  }
}

@media (min-width: 768px) {
  .insidediv {
    width: 85%;
  }
}

.insidediv p {
  line-height: 2rem;
}

.howitworks {
  overflow-x: hidden;
}

.howitworks .row {
  margin: 0;
}

.overflowx-hidden {
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mainnews {
  padding-bottom: 6rem;
}

.overflowx-hidden::-webkit-scrollbar {
  display: none;
}

.howitworks .row:last-child {
  padding-bottom: 5rem;
}

@media (min-width: 1800px) {
  .container {
    max-width: 1600px;
  }
}

.MyPZLink_mypz-link__L4Ljg.MyPZLink_simple__KFEc7 {
  cursor: pointer;
  display: inline-block;
  line-height: 12px;
}

.HomePage_home-page__section-title__YMeKx {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.2rem;
  text-align: left;
  margin: 1rem 0;
}

.linksection h3 {
  font-family: "Gilroy-Bold ☞";
}

.linksection a {
  text-decoration: none;
  font-size: 0.8rem;
  color: #414141 !important;
}

@media (min-width: 768px) {
  .blogimage {
    padding-bottom: 40%;
  }
}

@media (max-width: 768px) {
  .blogimage {
    padding-bottom: 50%;
  }
}

.blogtext img {
  max-width: 500px;
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}

.blogtext p {
  margin-bottom: 1rem;
}

.twopartdropdowndiv {
  display: flex;
  max-height: 300px;
  overflow-y: scroll;
  padding: 0 0.25rem;
}

.twopartdropdowndiv .radiolist.monolabel {
  width: 50%;
}

.inputwithicondiv.location-input {
  position: relative;
}

.inputwithicondiv.location-input .location-badge {
  background-color: #111;
  border-radius: 1rem;
  display: flex;
  gap: 0.35rem;
  padding: 0.4rem 0.75rem;
  align-items: center;
  color: #fff;
  font-size: 12px;
}

.inputwithicondiv.location-input .location-badge a {
  text-decoration: none;
  color: #fff;
  align-items: center;
  display: grid;
}

.inputwithicondiv svg.closeBtn,
.inputwithicondiv i.closeBtn {
  position: static;
  bottom: 0;
  font-size: 12px;
  transform: none;
  color: #fff;
  border-radius: 50%;
  padding: 0.25rem;
  box-sizing: content-box;
  width: 0.75rem;
  height: 0.75rem;
  display: grid;
  align-items: center;
  justify-content: center;
}

.inputwithicondiv.location-input .location-badge a:hover svg,
.inputwithicondiv.location-input .location-badge a:hover i {
  background-color: #292929;
}

.location-badges {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 0.25rem;
}

.more-locations-badge {
  text-decoration: none;
  background-color: #111;
  border-radius: 1rem;
  gap: 0.35rem;
  padding: 0.5rem 0.75rem;
  font-size: 12px;
  border: none;
  display: none;
}

.more-locations-badge.dropdown-toggle::after {
  display: none;
}

.more-locations-badge:hover {
  background-color: #292929;
}

.location-badges .dropdown-item {
  display: flex;
  text-align: left;
  justify-content: flex-start;
  padding: 0;
}

.location-badges .dropdown-item:hover {
  background: none;
}

.location-badges .dropdown-menu {
  gap: 0.5rem;
  margin-bottom: 0.5rem !important;
  width: 18rem;
  flex-wrap: wrap;
  padding: 0.75rem;
}

.location-badges .dropdown-menu.show {
  display: flex;
}

.more-locations-badge:focus,
.more-locations-badge:active {
  background-color: #111 !important;
}

.location-menu {
  background-color: #fff;
  position: absolute;
  height: 0;
  width: 100%;
  border-radius: 0.5rem;
  opacity: 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: scroll;
  z-index: 9999;
  pointer-events: none;
}

.location-menu.show-it-now {
  display: block !important;
  opacity: 1;
  pointer-events: all;
  height: auto;
}

.location-menu button {
  background: none;
  border: none;
  width: 100%;
  padding: 0.75rem 0rem 0.75rem 0.75rem;
  text-align: left;
}

.location-menu button:hover {
  background-color: #eceef2;
}

.location-menu-modal {
  background-color: #fff;
  position: absolute;
  width: 100%;
  border-radius: 0.5rem;
  opacity: 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: scroll;
  z-index: 9999;
  pointer-events: none;
}

.location-menu-modal.show-it-now {
  display: block !important;
  opacity: 1;
  pointer-events: all;
}

.location-menu-modal button {
  background: none;
  border: none;
  width: 100%;
  padding: 0.75rem 0rem 0.75rem 0.75rem;
  text-align: left;
}

.location-menu-modal button:hover {
  background-color: #eceef2;
}

.more-locations-badge.show-it-now {
  display: flex;
}

.inputwithicondiv.location-input .location-badge.firstCitybadge.show-it-now {
  display: flex !important;
}

.inputwithicondiv.location-input .location-badge.firstCitybadge span:empty {
  display: none;
}

.inputwithicondiv.location-input .location-badge.firstCitybadge {
  display: none;
}

.boring-list li {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}

.boring-list li svg {
  color: var(--bs-success);
  font-size: 16px;
  display: block;
  box-sizing: content-box;
  min-width: 1rem;
  display: grid;
}

.boring-list {
  gap: 1rem;
  display: grid;
}

@media (min-width: 768px) {
  .aboutuscard {
    width: calc(50% - 1rem);
  }
}

@media (min-width: 768px) {
  .aboutuscard-parent {
    display: flex;
    flex-wrap: wrap;
  }
}

.aboutuscard-number {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  display: grid;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  background-color: #ebeff6;
  border-radius: 50%;
  padding: 0.3rem;
  color: #1c1c1c;
}

.aboutuscard-parent {
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .howitworks .col {
    min-height: 50vh;
    max-height: 850px;
    display: grid;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .insidediv h2.gilroybold {
    font-size: 29px;
  }
}

.detailFav {
  -webkit-text-fill-color: #ff0000;
  -webkit-text-stroke-color: #ff0000;
}
