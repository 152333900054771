.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgb(17, 17, 17);
  --bs-navbar-hover-color: rgba(42, 42, 42, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgb(17, 17, 17);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.hr-5 {
  width: 5em;
  color: #bbb;
  margin: 0.5rem 0;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--bs-navbar-active-color);
  font-family: "Gilroy-Bold ☞";
}

.rentorbuyform {
  display: flex;
  gap: 0;
}

.rentorbuyform .form-check:last-child label {
  border-top-right-radius: 1.5rem;
  border-left-width: 0.5px;
}

.rentorbuyform .form-check:first-child label {
  border-top-left-radius: 1.5rem;
  border-right-width: 0.5px;
}

.myinput {
  border: 1px solid #bbb;
  border-radius: 0.75rem;
  height: 3rem;
}

.carousel-control-next-icon {
  padding-left: 2px;
}

.carousel-control-prev-icon {
  padding-right: 2px;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 0.5rem;
  height: 0.5rem;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
  border-radius: 50%;
}

.itemmotherdiv .carousel-indicators .active {
  opacity: 1;
}

.itemmotherdiv .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 0;
  margin-left: 15%;
  list-style: none;
}


.paginationv2 {
  display: flex;
  list-style: none;
  margin: auto;
  justify-content: space-around;
  font-size: 16px;
  --bs-pagination-color: #bcbcbc;
  --bs-pagination-bg: none;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: none;
  --bs-pagination-border-radius: 50%;
  --bs-pagination-hover-color: var(--bs-secondary);
  --bs-pagination-hover-bg: none;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: none;
  --bs-pagination-focus-bg: none;
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-color: #000000;
  --bs-pagination-active-bg: none;
  --bs-pagination-active-border-color: none;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: none;
  --bs-pagination-disabled-border-color: none;
  font-family: "Gilroy-Bold ☞";
}
.sub-pagination {
  display: flex;
  list-style: none;
  margin: auto;
  justify-content: space-around;
  font-size: 12px;
  --bs-pagination-color: #bcbcbc;
  --bs-pagination-bg: none;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: none;
  --bs-pagination-border-radius: 50%;
  --bs-pagination-hover-color: var(--bs-secondary);
  --bs-pagination-hover-bg: none;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: none;
  --bs-pagination-focus-bg: none;
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-color: #000000;
  --bs-pagination-active-bg: none;
  --bs-pagination-active-border-color: none;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: none;
  --bs-pagination-disabled-border-color: none;
  font-family: "Gilroy-Bold ☞";
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #111111;
  --bs-btn-border-color: #111111;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.form-check-input:checked {
  background-color: #111111;
  border-color: #111111;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #25d366;
  --bs-btn-border-color: #25d366;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1cad52;
  --bs-btn-hover-border-color: #1cad52;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-outline-dark {
  --bs-btn-color: #111111;
  --bs-btn-border-color: #111111;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #111111;
  --bs-btn-hover-border-color: #111111;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.75rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: none;
  border-radius: 1.5rem;
  outline: 0;
}

.modal-title {
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: var(--bs-modal-title-line-height);
  font-family: "Gilroy-Bold ☞";
}

@media (min-width: 992px) {
  .modal-dialog-end {
    display: flex;
    align-items: flex-end;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
    padding-bottom: 3rem;
  }
}

@media (max-width: 992px) {
  .modal-dialog-end {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
  }
}

.tepearkaplanlidiv {
  display: grid;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 1.5rem;
}

@media (min-width: 768px) {
  .tepearkaplanlidiv {
    min-height: calc(100vh - 230px);
    margin-top: 0.5rem;
  }
}

@media (max-width: 768px) {
  .tepearkaplanlidiv {
    max-height: 750px;
  }
}

@media (min-width: 768px) {
  .tepebaslik {
    text-align: left;
    font-size: 4.5rem;
  }
}

@media (max-width: 768px) {
  .tepebaslik {
    text-align: center;
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  h1.tepebaslik {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {
  h1.tepebaslik {
    font-size: 2.5rem;
  }
}

.howitworks img {
  width: 85%;
  margin: auto;
  -webkit-filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.35));
  filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.35));
}

.text-muted {
  --bs-text-opacity: 1;
  color: #50565c !important;
}
.text-footer{
  color: white !important;
}

@media (min-width: 768px) {
  .homepageheading {
    font-size: 44px;
  }
}

@media (max-width: 768px) {
  .homepageheading {
    font-size: 29px;
  }
}
@media (min-width: 768px) {
  .homesubtitle {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .homesubtitle {
    font-size: 16px;
  }
}
  .mainPadding {
    padding-bottom: 5%;
  }

.reset-button {
  float: right;
  padding-right: 5px;
  color: rgb(255, 0, 0);
  transition-duration: 300ms;
}

.reset-button:hover {
  cursor: pointer;
  color: rgb(255, 255, 255);
}

.carousel-indicators {
  opacity: 1 !important;
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(180, 49, 49);
  animation: lds-grid 1.2s linear infinite;
}

.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  animation-delay: 0s;
}

.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  animation-delay: -0.4s;
}

.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  animation-delay: -0.8s;
}

.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  animation-delay: -1.2s;
}

.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  animation-delay: -1.6s;
}

@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

a {
  cursor: pointer !important;
}

.badge-success {
  color: rgb(7 53 10);
  background-color: rgb(201 233 201);
  padding: 0.6em 1em;
  border-radius: 12px;
}

.badge-danger {
  color: rgb(53, 7, 7);
  background-color: rgb(233, 201, 201);
  padding: 0.6em 1em;
  border-radius: 12px;
}

.MuiAlert-icon {
  opacity: 0.9;
  font-size: 22px;
  margin-right: 12px;
  width: 26px;
  float: left;
  margin-right: 10px;
  margin-top: -7px;
}

.display-none {
  display: none !important;
}

.links-underlined > a,
.links-underlined > div {
  font-size: 0.8em;
  margin-bottom: 0.4em;
}

.links-underlined > a:hover,
.links-underlined > div:hover {
  text-decoration: underline !important;
}

.links-underlined-regular > a:hover {
  text-decoration: underline !important;
}

.bold {
  font-family: "Gilroy-Bold ☞";
}

.pr-1 {
  padding: 0 !important;
  padding-right: 1em !important;
}

.table {
  column-count: 2;
  column-fill: balance;
  column-gap: 50px;
  column-fill: auto;
  column-rule: 2px outset rgb(236, 236, 238);
  border: 1px solid #dfdfdf;
  border-radius: 20px;
}

.form {
  align-content: flex-end;
  min-height: 100%;
  width: 50%;
}

.inline-input {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.box-style {
  border: 1px solid #bbb;
  color: #111111;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.05);
  border-radius: 1.5rem;
  max-width: "100%";
  max-height: "100%";
}

@media (min-width: 992px) {
  .page-container {
    max-width: 1140px;
    margin: 0 auto;
  }

  .page-sidenav {
    display: block !important;
  }
}

.w-32 {
  width: 32px !important;
  height: 32px !important;
  font-size: 0.85em;
}

.tl-item .avatar {
  z-index: 2;
}

.circle {
  border-radius: 500px;
}

.gd-warning {
  color: #fff;
  border: none;
  background: #f4c414 linear-gradient(45deg, #f4c414, #f45414);
}

.timeline {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
  padding: 0;
  margin: 0;
}

.p-4 {
  padding: 1.5rem !important;
}

.block,
.card {
  background: #fff;
  border-width: 0;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.tl-item {
  border-radius: 3px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
}

.tl-item > * {
  padding: 10px;
}

.tl-item .avatar {
  z-index: 2;
}

.tl-item:last-child .tl-dot:after {
  display: none;
}

.tl-item.active .tl-dot:before {
  border-color: #000000;
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
  background-color: #000;
}

.tl-item.passive .tl-dot:before {
  border-color: #818a93;
  background-color: #818a93;
}

.tl-item:last-child .tl-dot:after {
  display: none;
}

.tl-item.active .tl-dot:before {
  border-color: #000000;
  box-shadow: 0 0 0 6px rgba(94, 85, 85, 0.2);
  background-color: #000;
}

.tl-item.passive .tl-dot:before {
  border-color: #000000;
  background-color: #000000;
}

.tl-dot {
  position: relative;
  border-color: rgba(84, 87, 90, 0.15);
}

.tl-dot:after,
.tl-dot:before {
  content: "";
  position: absolute;
  border-color: inherit;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
}

.tl-dot:after {
  width: 0;
  height: auto;
  top: 25px;
  bottom: -15px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
  background-color: #000;
}

tl-item.active .tl-dot:before {
  border-color: #000000;
  box-shadow: 0 0 0 20px rgba(68, 139, 255, 0.2);
  background-color: #000;
}

.tl-dot {
  position: relative;
  border-color: rgba(0, 0, 0, 0.15);
}

.tl-dot:after,
.tl-dot:before {
  content: "";
  position: absolute;
  border-color: inherit;
  border-width: 1.2px;
  background-color: #818a93;
  border-style: solid;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.tl-dot:after {
  width: 0;
  height: auto;
  top: 26px;
  bottom: -15px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
  background-color: #000;
}

.tl-content p:last-child {
  margin-bottom: 15px;
}

.tl-date {
  font-size: 0.85em;
  margin-top: 2px;
  min-width: 100px;
  max-width: 100px;
}

.avatar {
  position: relative;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: 700;
  border-radius: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 500px;
  box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.15);
}

.b-warning {
  border-color: #818a93 !important;
}

.b-primary {
  border-color: #000000 !important;
}

.b-danger {
  border-color: #818a93 !important;
}

.card-style {
  background: linear-gradient(127deg, #f2f4f8, #ffffff 100%);
  color: #111111;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.05);
  border-radius: 1.5rem;
  max-width: 100%;
}

thead,
tfoot {
  color: #000;
}

caption {
  padding: 10px;
  caption-side: bottom;
}

table {
  letter-spacing: 1px;
  font-size: 0.9rem;
  align-content: center;
  justify-content: center;
  align-self: center;
  width: "100%";
  height: "100%";
}

td,
th {
  padding: 5px 10px;
}

td {
  text-align: center;
}

.inputGroup {
  background-color: #fff;
  margin: 10px 0;
  position: relative;
  border-radius: 15px;
  justify-content: flex-start;
}

.inputGroup label {
  padding: 12px 30px;
  width: 100%;
  display: block;
  text-align: left;
  color: #000;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
  border-radius: 15px;
}

.inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  content: "";
  background-color: #000000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}

.inputGroup label:after {
  width: 32px;
  height: 32px;
  content: "";
  border: 4px solid #d1d7dc;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 2px 3px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 200ms ease-in;
  border-radius: 25px;
}

.inputGroup input:checked ~ label {
  color: #fff;
}

.inputGroup input:checked ~ label:before {
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
  border-radius: 25px;
}

.inputGroup input:checked ~ label:after {
  background-color: #000000;
  border-color: #ffffff;
  border-radius: 25px;
}

.inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
  border-radius: 15px;
}

.form-form {
  max-width: 550px;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.sub-items {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
}

.sub-item {
  margin: 0px 0 0 0;
}

.sub-item:before {
  background: #ddd;
  content: "";
  height: 100%;
  left: 50%;
  position: absolute;
  top: -10px;
  transform: translateX(-50%);
  width: 2px;
  z-index: -1;
}

.progress-bar {
  width: 100%;
  height: 15px;
  background-color: #e4e4e4;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
  cursor: pointer;
}

.progress-bar-dot {
  margin-right: -4px;
  margin-top: 11px;
  font-size: 75px;
  color: #fff;
  align-self: center;
  top: 50px;
  left: 0;
}

.progress-bar-fill {
  height: 100%;
  background-color: #000000;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.2s ease-in-out;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* .progress-indicator {
  font-size: 18px;
  color: #fff;
  background-color: #0278ae;
  border: 2px solid #fff;
  padding: 4px 10px;
  position: absolute;
  top: -35px;
  left: 50%;
  transform: rotate(-45deg) translate(-50%, 0%);
} */

/* .progress-container {
  position: relative;
} */

/* .progress-bar-text {
  color: #000;
  font-size: 15px;
  background-color: #fff;
  width: 50px;
  height: 50px;
  border: #000;
  border-radius: 10px;
  position: absolute;
  top: -20 !important;
  left: 0;
  z-index: 999;
} */

.three-way-input {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;
  border-radius: 15px;
}

.card-info {
  background-color: #f5f6f6;
  color: #111111;
  border-radius: 1.5rem;
  width: 100px;
}

.checkout-timeline {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

.step:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  background-color: #ccc;
  transform: translateX(-50%);
}

.step:last-child:before {
  height: 50%;
}

.step.active:before {
  background-color: #333;
}

.step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.step-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.step-description {
  font-size: 0.8rem;
  color: #666;
}

.step.active .step-content {
  border-color: #333;
}

.step.active .step-title {
  color: #333;
}

.tl-subitem {
  margin-left: 30px;
  font-size: 14px;
  color: #888;
}

.tl-subitem::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #c5c5c5;
}

.tl-subitem:last-child::before {
  display: none;
}

.grid-3-column {
  gap: 0%;
}

.card-3-column {
  border-radius: 25px;
  padding: 0;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.05);
}

.card_title {
  font-weight: normal;
  font-size: 36px;
  margin-bottom: 20px;
}

.pricing {
  font-weight: normal;
  font-size: 96px;
}

.pricing .small {
  font-size: 16px;
}

hr {
  margin-top: 70px;
}

.features {
  list-style-position: inside;
}

.features li {
  padding-bottom: 10px;
}

a.cta_btn {
  width: 45%;
  height: "15px";
  display: inline-block;
  text-align: center;
  background: #336fff;
  border-radius: 15px;
  padding: 18px 0;
  color: white;
  text-decoration: none;
  letter-spacing: 2px;
  transition: background 0.3s ease;
  margin-left: 30%;
  margin-top: 0px;
  margin-bottom: 40px;
  font-size: 16px;
}

a.cta_btn:hover {
  background: #123ca0;
}

.link:hover {
  text-decoration: underline;
  background-color: black;
}

.hr-small {
  margin-top: 10px;
}

@media only screen and (max-width: 1024px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    gap: 2%;
  }
}

@media only screen and (max-width: 425px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 2%;
    padding-bottom: 25%;
  }

  .container {
    padding: 0 1rem;
  }

  body {
    font-size: 14px;
  }

  .card {
    padding: 0px;
  }

  .card_title {
    font-size: 16px;
    margin-bottom: 12px;
    text-align: center;
    line-height: 1.8rem;
    font-weight: 700;
  }

  .pricing {
    font-size: 25px;
  }

  hr {
    margin-top: 50px;
  }

  a.cta_btn {
    font-size: 11px;
  }
}

.button {
  float: left;
  margin: 0 5px 0 0;
  width: 100px;
  height: 40px;
  position: relative;
}

.button label,
.button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.button input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.button input[type="radio"]:checked + label {
  background: #e1e3e3;
  border-radius: 10px;
}

.button label {
  cursor: pointer;
  z-index: 90;
  line-height: 1.8em;
}

.inputGroup-3-column {
  background-color: #fff;
  display: block;
  margin: 20px 0;
  position: relative;
  border-radius: 15px;
}

.radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
}

.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.radio input:checked ~ .checkmark {
  background-color: black;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio input:checked ~ .checkmark:after {
  display: block;
}

.radio .checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 14px;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.active .checkmark {
  background-color: #000000;
}

.active .checkmark:after {
  display: block;
}

.card {
  border-radius: 15px !important;
  border: 1px solid #ccc !important;
}

.card:nth-of-type(2)::before {
  background-color: var(--color06);
  color: var(--color03);
}

.card:nth-of-type(3)::before {
  background-color: var(--color07);
  color: var(--color12);
}

.card__info {
  width: 100%;
  height: 30px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  position: relative;
  z-index: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card__info::before {
  background-color: var(--color02);
  width: 100%;
  height: 100%;
  opacity: 0.8;

  content: "";
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
}

.price-card-title {
  background-color: black;
  color: white;
  width: 100%;
  min-height: 150px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  font-size: 40px;
}

.container-for-card {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 300px));
  gap: 25px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 644px) {
  .container-for-card {
    gap: 30px;
  }
}

.card-3-row {
  width: 300px;
  min-height: 470px;
  border-radius: 15px 15px 0 0;
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;

  /* Flex */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-3-row :hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transform: scale(1.04);
}

.card-3-row ::before {
  background-color: var(--color05);
  color: var(--color02);
  padding: 20px 35px 10px;

  transform: translate(calc(31px + 35px), -35px) rotate(45deg);
  transform-origin: top left;

  content: "NEW";
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.card-3-row :nth-of-type(2)::before {
  background-color: var(--color06);
  color: var(--color03);
}

.card-3-row :nth-of-type(3)::before {
  background-color: var(--color07);
  color: var(--color12);
}

/* Info */
.card__info {
  width: 100%;
  height: 130px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  position: relative;
  z-index: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card__info::before {
  background-color: var(--color02);
  width: 100%;
  height: 100%;
  opacity: 0.8;

  content: "";
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
}

@media only screen and (max-width: 768px) {
  .content-for-card {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .card-for-mobile {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

.offer-check {
  font-size: 22px;
  margin-top: -4px;
  font-family: "Gilroy-Bold ☞" !important;
}

.range-slider-container > h2 {
  padding: 30px 0;
  position: relative;
  max-width: 100%;
  margin: 40px auto;
}

.range-slider-container h3 {
  font-size: 16px;
}

.range-slider-container .rs-value {
  position: absolute;
  top: -20px;
  background: #1c2126;
  padding: 0.25em 1em;
  color: #fff;
  border-radius: 0.5rem;
}

.range-slider-container .rs-value:after {
  top: 100%;
  bottom: 20%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(28, 33, 38, 0);
  border-top-color: #1c2126;
  border-width: 10px;
  margin-left: -10px;
}

.range-slider-container .min-max {
  color: #000000;
}

.rs-range {
  background-color: #000;
}

.range-slider-container input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  border-radius: 2rem;
  height: 10px;
  margin: 8.5px 0;
  background: linear-gradient(to right, #d5d9da 0%, #d5d9da 100%);
  transition: #000 450ms ease-in;
}

.range-slider-container input[type="range"]:focus {
  outline: none;
}

.range-slider-container input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border-radius: 1px;
  border: 0px solid #ffffff;
}

.range-slider-container input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1.5px 1.5px 2.5px rgba(0, 0, 0, 0.5),
    0px 0px 1.5px rgba(13, 13, 13, 0.5);
  border: 0px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 25px;
  background: #000000;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}

.range-slider-container input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border-radius: 0px;
  border: 0px solid #ffffff;
}

.range-slider-container input[type="range"]::-moz-range-thumb {
  box-shadow: 1.5px 1.5px 2.5px rgba(0, 0, 0, 0.5),
    0px 0px 1.5px rgba(13, 13, 13, 0.5);
  border: 0px solid #000000;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background: #000000;
  cursor: pointer;
}

.range-slider-container input[type="range"]::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.range-slider-container input[type="range"]::-ms-fill-lower {
  background: #000000;
  border: 0px solid #ffffff;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

.range-slider-container input[type="range"]::-ms-fill-upper {
  border: 0px solid #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}

.range-slider-container input[type="range"]::-ms-thumb {
  box-shadow: 1.5px 1.5px 2.5px rgba(0, 0, 0, 0.5),
    0px 0px 1.5px rgba(13, 13, 13, 0.5);
  border: px solid #000000;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background: #ffffff;
  cursor: pointer;
  height: 8px;
}

.main-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

.step-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.step-container:after {
  content: "";
  position: absolute;
  background: #818a93;
  height: 3px;
  width: 100%;
  top: 50%;
  transition: 0.4s ease;
  transform: translateY(-50%);
  left: 0;
}

.step-container:before {
  content: "";
  position: absolute;
  background: #f3e7f3;
  height: 4px;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.step-wrapper {
  position: relative;
  z-index: 1;
}

.step-style {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid "#000" "#818a93";
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-count {
  font-size: 16px;
  color: #818a93;
  font-weight: bolder;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.steps-label-container {
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.step-label {
  font-size: 16px;
  color: #000;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.check-mark {
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  -ms-transform: scaleX(-1) rotate(-46deg);
  /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg);
  /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
}

@media only screen and (max-width: 1024px) {
  .card-padding-top {
    padding-top: -30px;
  }
}

@media (max-width: 768px) {
  .progress-step {
    flex-wrap: wrap;
  }

  .progress-step-item {
    flex-basis: 100%;
    text-align: left;
    display: block;
  }

  .progress-step-bar {
    flex-basis: 100%;
    height: 1px;
  }
}

@media only screen and (min-width: 1440px) {
  .flex-start-text {
    padding-left: 28%;
  }
}

@media only screen and (min-width: 1440px) {
  .flex-start-button {
    padding-left: 27%;
  }
}

.offer-header {
  font-size: 3rem;
  line-height: 3.5rem;
}

.offer-subheader {
  font-size: 1.6rem;
  padding-top: 20px;
}

.offer-header-small {
  font-size: 2.25rem;
}

.check-icon {
  color: #336fff;
  margin-top: 2px;
  font-size: 20px;
  margin-right: 10px;
}
.cgpTmY {
  border-radius: 50%;
}

.grecaptcha-badge {
  bottom: 130px !important;
}

.intercom-namespace {
  bottom: 70px !important;
}

.range-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: black;
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: black;
  cursor: pointer;
}

.arrow {
  border: solid grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  border-radius: 4px 0;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.border-0 {
  border: 0 !important;
}

.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  color: #ffffff !important;
  background: black !important;
  border-color: #000000 !important;
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
  color: #ffffff !important;
  background: #3498ff !important;
}

.rs-steps-item-status-finish .rs-steps-item-tail,
.rs-steps-item-status-finish .rs-steps-item-title::after {
  border-color: black !important;
}

.list-right > ul > li > span {
  text-align: right !important;
}

.mb-6 {
  margin-bottom: 6em !important;
}

.short-desc {
  font-size: 0.8em;
  text-overflow: inherit;
  white-space: inherit;
  overflow: hidden;
  max-width: auto;
  float: left;
}

.long-desc {
  font-size: 0.8em;
  text-overflow: inherit;
  white-space: inherit;
  overflow: hidden;
  max-width: 100%;
  float: left;
  height: auto;
  display: block;
}

.list-badge {
  background: black;
  color: white;
  position: absolute;
  width: auto;
  margin: 1rem;
  font-size: 0.9rem;
  padding: 1px 7px;
  border-radius: 0.5rem;
}

@media (max-width: 992px) {
  .no-mobile {
    display: none !important;
  }
}

.loadingFade {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffffe6;
  z-index: 9;
}

.link-light {
  text-decoration: none;
  font-family: "Gilroy-Light ☞";
  font-size: 16px;
}
.logo-container {
  text-align: center;
}

@media (max-width: 767px) {
  .logo-container {
    text-align: center;
  }

  .logo-container img {
    width: 140px;
    height: auto; 
  }
}
.button-section{
  margin-right: 5px;
}

